/* roboto-mono-200 - latin */
@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 200;
  src: local(""),
    url("../../bin/fonts/Roboto_Mono/roboto-mono-v13-latin-200.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../bin/fonts/Roboto_Mono/roboto-mono-v13-latin-200.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-mono-300 - latin */
@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 300;
  src: local(""),
    url("../../bin/fonts/Roboto_Mono/roboto-mono-v13-latin-300.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../bin/fonts/Roboto_Mono/roboto-mono-v13-latin-300.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-mono-regular - latin */
@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  src: local(""),
    url("../../bin/fonts/Roboto_Mono/roboto-mono-v13-latin-regular.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../bin/fonts/Roboto_Mono/roboto-mono-v13-latin-regular.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-mono-500 - latin */
@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 500;
  src: local(""),
    url("../../bin/fonts/Roboto_Mono/roboto-mono-v13-latin-500.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../bin/fonts/Roboto_Mono/roboto-mono-v13-latin-500.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-mono-600 - latin */
@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 600;
  src: local(""),
    url("../../bin/fonts/Roboto_Mono/roboto-mono-v13-latin-600.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../bin/fonts/Roboto_Mono/roboto-mono-v13-latin-600.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-mono-700 - latin */
@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 700;
  src: local(""),
    url("../../bin/fonts/Roboto_Mono/roboto-mono-v13-latin-700.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../bin/fonts/Roboto_Mono/roboto-mono-v13-latin-700.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
