.navbar {
    box-shadow: 0px 6px 15px 5px rgba(0, 0, 0, 0.06);
    padding: 20px;

    &-brand {
        text-decoration: none;
        color: black;
        height: fit-content;
        display: inline-block;

        svg {
            width: 130px;
            user-select: none;
        }
    }
}