@use "./abstracts/variables" as *;
@use "./abstracts";
@use "./vendors";
@use "./base";
@use "./components";
@use "./layout";
@use "./pages";
@use "./templates";
@use "./themes";

hr {
    background-color: rgba(0, 0, 0, 0.4);
}

span.code {
    display: inline;
    font-size: .8rem;
    font-family: Roboto Mono;
    background-color: #eeeeee;
    padding: 5px;
    border-radius: 2px;
    width: fit-content;
}

.block-typography, .block-buttons, .block-border, .block-tables, .block-cards, .block-input {
    .col-12 {
        & > * {
            margin-bottom: 15px !important;
    
             &:last-child {
                margin-bottom: unset !important;
             }
        }
    }
}