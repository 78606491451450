.color {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  &-rendered {
    height: 80px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 3px 3px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-title {
    background: rgba(0, 0, 0, 0.3);
    padding: 10px;
    color: white;
    border-radius: 3px;
  }

  &-copy {
    width: 16px;
    margin-left: 8px;
    fill: rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: 0.2s;

    &:active {
      fill: rgba(0, 0, 0, 0.45);
    }
  }
}

.colors {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0 0 3px 3px;
  border-top: none;
  vertical-align: middle;
  padding: 10px;
  
  span {

  }
}