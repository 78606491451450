.code {
  display: flex;
  align-items: flex-start;
  width: 100%;

  .code-block {
    margin: 0 !important;
    border-radius: 2px !important;
    width: 100%;
    background-color: rgb(249, 249, 249) !important;
    border: 1px solid rgba(0, 0, 0, 0.05) !important;
  }

  .copy-button {
    margin-left: -30px;
    margin-top: 14px;
  }
}

pre {
  margin: 0 !important;
}