.basic-table {
  border-collapse: collapse;
  text-align: center;
  width: 100%;

  thead,
  tr:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.02);
  }

  thead {
    background-color: rgba(0, 0, 0, 0.06);
  }

  tr {
    &:nth-child(even) {
      background-color: rgba(0, 0, 0, 0.06);
    }

    height: 50px;
  }

  td {
    height: 70px;
  }

  th,
  td {
    width: calc(100% / 4);
  }
}