.block {
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 25px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.03);
  margin-bottom: 25px;
}

.info-block {
    border: 1px solid rgba(0, 0, 0, 0.05);
    padding: 25px;
    line-height: 2;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.03);
    box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.03);
}
  